import { createContext, useEffect, useReducer, useCallback, useMemo, useState, Dispatch } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updatePassword,
  onIdTokenChanged,
  updateCurrentUser,
  User,
  beforeAuthStateChanged,
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';
// config
import { FIREBASE_API } from '../config-global';
//
import { ActionMapType, AuthStateType, AuthUserType, FirebaseContextType } from './types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  UPDATE_TOKEN = 'UPDATE_TOKEN'
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  },
  [Types.UPDATE_TOKEN]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const GOOGLE_PROVIDER = new GoogleAuthProvider();

const GITHUB_PROVIDER = new GithubAuthProvider();

const TWITTER_PROVIDER = new TwitterAuthProvider();

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
 
  useEffect(() => {
    let intervalId = null;

    const fetchToken = async (user) => {
      if (user) {
        await user.getIdToken(true);
  
        const userRef = doc(DB, 'users', user.uid);
  
        const docSnap = await getDoc(userRef);
  
        const profile = docSnap.data();
  
        // TODO: Fix ts-ignore
        // @ts-ignore 
        const userCustomAttributes = user?.reloadUserInfo?.customAttributes;
        let userRole = '';
  
        try {
          userRole = JSON.parse(userCustomAttributes)['https://hasura.io/jwt/claims']['x-hasura-default-role']
        } catch (e) {
          console.log(e, 'exception')
        }
        
        dispatch({
          type: Types.INITIAL, //TODO: Consider having different type for this
          payload: {
            isAuthenticated: true,
            user: {
              ...user,
              ...profile,
                role: userRole,
            },
          },
        });
      }
    };

    const authListener = onAuthStateChanged(AUTH, async (user) => {
      setTimeout(async () => {
        if (user) {
          await fetchToken(user);
          
          intervalId = setInterval(() => {
            fetchToken(user);
          }, 20 * 60 * 1000); // 20 minutes
        } else {
          if (intervalId) {
            clearInterval(intervalId);
          }
        }
      }, 20 * 60 * 1000) // 20 minutes
    });

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      authListener();
    };
  }, []);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const userRef = doc(DB, 'users', user.uid);

          const docSnap = await getDoc(userRef);

          const profile = docSnap.data();

          // TODO: Fix ts-ignore
          // @ts-ignore 
          const userCustomAttributes = user?.reloadUserInfo?.customAttributes;
          let userRole = '';

          try {
            userRole = JSON.parse(userCustomAttributes)['https://hasura.io/jwt/claims']['x-hasura-default-role']
          } catch (e) {
            console.log(e, 'exception')
          }

          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: true,
              user: {
                ...user,
                ...profile,
                role: userRole,
              },
            },
          });
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback((email: string, password: string) => signInWithEmailAndPassword(AUTH, email, password), []);

  const loginWithGoogle = useCallback(() => signInWithPopup(AUTH, GOOGLE_PROVIDER), []);

  const loginWithGithub = useCallback(() => signInWithPopup(AUTH, GITHUB_PROVIDER), []);

  const loginWithTwitter = useCallback(() => signInWithPopup(AUTH, TWITTER_PROVIDER), []);

  // REGISTER
  const register = useCallback(
    (email: string, password: string, firstName: string, lastName: string) => {
      createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
        const userRef = doc(collection(DB, 'users'), res.user?.uid);

        await setDoc(userRef, {
          uid: res.user?.uid,
          email,
          displayName: `${firstName} ${lastName}`,
        });
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(() => signOut(AUTH), []);

  // RESET PASSWORD
  const resetPassword = useCallback((newPassword: string) => {
    if (AUTH.currentUser) {
      return updatePassword(AUTH.currentUser, newPassword);
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'firebase',
      login,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
      register,
      logout,
      resetPassword
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
      register,
      logout,
      resetPassword
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

const shouldRefresh = (expirationTime: number, nowTime = new Date().getTime()): boolean => {
  console.log(expirationTime - nowTime, expirationTime - nowTime < 3000000, 'should?')
  if(expirationTime - nowTime < 3300000) {
    console.log('refresh')
    return true
  }
  console.log('false refresh')
  return false;
}

const fetchToken = async (user: User, dispatch: Dispatch<ActionsType>) => {
  if (user) {
    console.log(user, 'user fetchToken')
    await user.getIdToken(true);

    const userRef = doc(DB, 'users', user.uid);

    const docSnap = await getDoc(userRef);

    const profile = docSnap.data();

    // TODO: Fix ts-ignore
    // @ts-ignore 
    const userCustomAttributes = user?.reloadUserInfo?.customAttributes;
    let userRole = '';

    try {
      userRole = JSON.parse(userCustomAttributes)['https://hasura.io/jwt/claims']['x-hasura-default-role']
    } catch (e) {
      console.log(e, 'exception')
    }
    
    dispatch({
      type: Types.INITIAL, //TODO: Consider having different type for this
      payload: {
        isAuthenticated: true,
        user: {
          ...user,
          ...profile,
            role: userRole,
        },
      },
    });
  }
};